<template>
  <button
    :class="classStr"
    data-cy="login-button"
    type="button"
    @click="signInHandler"
  >
    Login
  </button>
</template>

<script setup lang="ts">
const { signIn } = useAuth();
const signInHandler = async () => {
  await signIn("auth0", {
    callbackUrl: "/",
    redirect: false,
  });
};
const { classStr } = defineProps({
  classStr: {
    type: String,
    default:
      "rounded-md bg-primary-50 px-3.5 py-2.5 text-sm font-semibold text-primary-400 shadow-sm hover:bg-primary-100",
  },
});
</script>
